<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span
          :class="{ 'text-decoration-line-through': !!formattedItem.fdesinstalacion }"
        >
          {{ formattedItem.title }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <extra-subtitle
        v-if="formattedItem.fechaDesinstalacion"
        :fecha-desinstalacion="formattedItem.fechaDesinstalacion"
      />
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import ExtraSubtitle from '../../components/ExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.descripcion
      item.subtitle = this.$online.materialSistema.resumenFichaTecnica(item).join(' - ')
      item.fechaDesinstalacion = item.fdesinstalacion
      item.avatar = item.nelemento || '?'
      item.avatarColor = 'info'
      return item
    }
  },
}
</script>